<template>
  <div>
    <c-table
      ref="scenarioTable"
      title="시나리오 항목"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :isExcelDown="false"
      :filtering="false"
      selection="multiple"
      rowKey="generationLocationId"
      :columns="grid.columns"
      :data="emerge.scenarios"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getDetail" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'law-gubun-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          trainingScenarioId: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'situationDevelopmentName',
            field: 'situationDevelopmentName',
            label: '상황전개',
            align: 'center',
            style: 'width:300px',
            sortable: false,
          },
        ],
        data: [],
        height: '500px'
      },
      emerge: {
        trainingScenarioId: '',
        deptCd: '',
        trainingScenarioName: '',
        emergencyPlanName: '',
        plantCd: '',
        useFlag: '',
        scenarios: [],
        deleteScenarios: [],
        evalItems: [],
        deleteEvalItems: [],
        regUserId: '',
        chgUserId: '',
      },
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.getUrl = selectConfig.sop.eap.emergTraining.pop.url;
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.trainingScenarioId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.trainingScenarioId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.emerge.scenarios = _result.data;
          this.updateMode = true;
        },);
      }
    },
    /* eslint-disable no-unused-vars */
    select() {
      let selectData = this.$refs['scenarioTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '상황전개를 선택하세요.', // 법령을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
